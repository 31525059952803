import { createContext } from 'react';
import type { User } from 'src/types/user';

export type PlatformStage = 'FirstTime' | 'EnrichmentInProcess' | 'AllowedToUsePlatform';

export interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  hasChambersMembers: boolean | null;
  platformStage: null | PlatformStage;
  tabsEnabled: boolean;
}

export const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  hasChambersMembers: null,
  platformStage: null,
  tabsEnabled: false,
};

export interface AuthContextType extends State {
  signIn: (email: string, password: string) => Promise<void>;
  googleSignin: (redirectUri: string, code: string) => Promise<void>;
  signInWithCognitoCode: (code: string) => Promise<any>;
  confirmSignUp: (token: string, refresh_token: string) => Promise<void>;
  magicTokenVerify: (email: string, magic_token: string) => Promise<void>;
  smbMagicToken: (email: string, magic_token: string, chatId?: string) => Promise<void>;
  // smbSignupEmail: (email: string, password: string, smb_id: string) => Promise<void>;
  smbSignupConfirmToken: (token: string) => Promise<void>;
  smbSignupGoogle: (idp_code: string, smb_id: string) => Promise<void>;
  smbSignupESToken: (token: string) => Promise<void>;
  changePassword: (email: string, password: string, session: string) => Promise<void>;
  setNewPassword: (email: string, temporalPassword: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<void>;
  setHasChamberMembers: (hasChamberMembers: boolean) => void;
  setPlatformStage: (stage: PlatformStage) => void;
  enableTabs: (enable: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  signIn: () => Promise.resolve(),
  googleSignin: () => Promise.resolve(),
  signInWithCognitoCode: () => Promise.resolve(),
  confirmSignUp: () => Promise.resolve(),
  magicTokenVerify: () => Promise.resolve(),
  smbMagicToken: () => Promise.resolve(),
  // smbSignupEmail: () => Promise.resolve(),
  smbSignupConfirmToken: () => Promise.resolve(),
  smbSignupGoogle: () => Promise.resolve(),
  smbSignupESToken: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  setNewPassword: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  setHasChamberMembers: (_: boolean) => {},
  setPlatformStage: (_: PlatformStage) => {},
  enableTabs: (_: boolean) => {},
});
