import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { MessageCircle, Compass, PanelRightClose, Settings, Zap } from 'lucide-react';
import AIAvatar from '../../common/ai-avatar';
import { useTypeWritingEffect } from 'src/hooks/use-typewriting-effect';
import { Tooltip } from '@lobehub/ui';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useDrawerActions, useDrawerState } from 'src/contexts/drawer/hooks';
import LogoutIcon from './logout-icon';
import { AppBar, leftBarDrawer, Main, Drawer } from 'src/layouts/common/styled-components';
import useEnrichmentProgressNotification from 'src/hooks/use-enrichment-progress-data';
import { NotificationEnrichmentExtra } from 'src/types/notifications';

interface MiniDrawerProps {
  children: ReactNode;
}

const useNovaTopMessage = () => {
  const [message, setMessage] = useState('');
  const { enrichmentNotification } = useEnrichmentProgressNotification();

  useEffect(() => {
    const checkNotifications = async () => {
      if (enrichmentNotification) {
        const { extra, text } = enrichmentNotification;
        if ((extra as NotificationEnrichmentExtra).progress < 100) {
          setMessage(text);
          return;
        }
      }
    };

    checkNotifications();
  }, [setMessage, enrichmentNotification]);

  return { novaMessage: message  };
};

const MiniDrawer: FC<MiniDrawerProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const { setDrawerOpen } = useDrawerActions();
  const { open, drawerWidth, drawerComponent } = useDrawerState();
  const { novaMessage } = useNovaTopMessage();
  const { setMessage, typedResponse } = useTypeWritingEffect('', 10, 0);

  useEffect(() => {
    if (novaMessage) {
      setMessage(novaMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novaMessage]);

  const { enrichmentNotification } = useEnrichmentProgressNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setDrawerOpen(!open);
  };

  const handleNavigation = (url: string) => {
    setDrawerOpen(url !== paths.workspace.insights);
    navigate(url);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ flex: 1, maxWidth: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, maxWidth: '100%' }}>
          <Drawer
            open={open}
            drawerWidth={drawerWidth}
          >
            <Box
              sx={{
                width: '70px',
                zIndex: 9999,
                borderRight: () => `1px solid ${theme.palette.grey[300]}`,
                bgcolor: '#f7f7f7',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <List>
                <ListItem>
                  <Tooltip
                    title="Insights"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        borderRadius: 1,
                        bgcolor: (theme) =>
                          location.pathname === paths.workspace.insights
                            ? theme.palette.grey[300]
                            : 'inherit',
                      }}
                      onClick={() => handleNavigation(paths.workspace.insights)}
                    >
                      <Compass />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <Tooltip
                    title="Chats"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        bgcolor: (theme) =>
                          location.pathname === paths.workspace.chat
                            ? theme.palette.grey[300]
                            : 'inherit',
                        borderRadius: 1,
                      }}
                      onClick={() => handleNavigation(paths.workspace.chat)}
                    >
                      <MessageCircle />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <Tooltip
                    title="Memory"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      sx={{
                        bgcolor: (theme) =>
                          location.pathname.startsWith(paths.workspace.memory.index)
                            ? theme.palette.grey[300]
                            : 'inherit',
                        borderRadius: 1,
                      }}
                      onClick={() => handleNavigation(paths.workspace.memory.index)}
                    >
                      <img
                        src="/assets/icons/artificial-brain-2.png"
                        width={27}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
                {/* <ListItem>
                  <Tooltip
                    title="Skills"
                    arrow
                    zIndex={9999}
                    placement="right"
                  >
                    <IconButton
                      disabled
                      sx={{
                        bgcolor: (theme) =>
                          location.pathname.startsWith(paths.workspace.skills.index)
                            ? theme.palette.grey[300]
                            : 'inherit',
                        borderRadius: 1,
                      }}
                      onClick={() => handleNavigation(paths.workspace.skills.index)}
                    >
                      <Zap />
                    </IconButton>
                  </Tooltip>
                </ListItem> */}
              </List>
              <IconButton
                sx={{
                  marginBottom: '1rem',
                  bgcolor: (theme) =>
                    location.pathname === paths.workspace.settings
                      ? theme.palette.grey[300]
                      : 'inherit',
                }}
                onClick={() => handleNavigation(paths.workspace.settings)}
              >
                <Tooltip
                  title="Settings"
                  arrow
                  zIndex={9999}
                  placement="right"
                >
                  <Settings />
                </Tooltip>
              </IconButton>
            </Box>
            <div style={{ width: drawerWidth, overflowX: 'hidden' }}>{drawerComponent}</div>
          </Drawer>
          <AppBar
            position="fixed"
            open={open}
            drawerWidth={drawerWidth + leftBarDrawer}
            sx={{ right: 'unset', bgcolor: 'white', color: '#000' }}
          >
            <Toolbar>
              {drawerWidth > 0 && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <PanelRightClose
                    style={{
                      transform: `rotate(${open ? 180 : 0}deg)`,
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  />
                </IconButton>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <AIAvatar enrichmentNotification={enrichmentNotification} />
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                  >
                    {typedResponse}
                  </Typography>
                </Stack>
                <LogoutIcon />
              </Stack>
            </Toolbar>
          </AppBar>
          <Main
            open={open}
            drawerWidth={drawerWidth + leftBarDrawer}
          >
            {children}
          </Main>
        </Box>
      </Stack>
    </>
  );
};

export default MiniDrawer;
