import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { authApi } from 'src/api/auth';
import type { User } from 'src/types/user';

import type { PlatformStage, State } from './auth-context';
import { AuthContext, initialState } from './auth-context';

export const STORAGE_KEY = 'accessToken';

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_OUT = 'SIGN_OUT',
  SET_HAS_CHAMBER_MEMBERS = 'SET_HAS_CHAMBER_MEMBERS',
  SET_PLATFORM_STAGE = 'SET_PLATFORM_STAGE',
  ENABLE_TABS = 'ENABLE_TABS',
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: User;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type SetHasChamberMembersAction = {
  type: ActionType.SET_HAS_CHAMBER_MEMBERS;
  payload: {
    hasChamberMembers: boolean;
  };
};

type SetPlatformStageAction = {
  type: ActionType.SET_PLATFORM_STAGE;
  payload: {
    stage: PlatformStage;
  };
};

type EnableTabsAction = {
  type: ActionType.ENABLE_TABS;
  payload: {
    enable: boolean;
  };
};

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction
  | SetHasChamberMembersAction
  | SetPlatformStageAction
  | EnableTabsAction;

type Handler = (state: State, action: any) => State;

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    tabsEnabled: false,
    platformStage: null,
  }),
  SET_HAS_CHAMBER_MEMBERS: (state: State, action: SetHasChamberMembersAction): State => ({
    ...state,
    hasChambersMembers: action.payload.hasChamberMembers,
  }),
  SET_PLATFORM_STAGE: (state: State, action: SetPlatformStageAction): State => ({
    ...state,
    platformStage: action.payload.stage,
  }),
  ENABLE_TABS: (state: State, action: EnableTabsAction): State => ({
    ...state,
    tabsEnabled: action.payload.enable,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = window.sessionStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const user = await authApi.me({ accessToken });

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const { accessToken } = await authApi.signIn({ email, password });
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const googleSignin = useCallback(
    async (redirectUri: string, code: string): Promise<void> => {
      const { accessToken } = await authApi.googleLogin(redirectUri, code);
      
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const confirmSignUp = useCallback(
    async (token: string, refresh_token: string): Promise<void> => {
      const { accessToken } = await authApi.confirmSignUp({ token, refresh_token });
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const magicTokenVerify = useCallback(
    async (email: string, magicToken: string): Promise<void> => {
      const { accessToken } = await authApi.verifyMagicToken(email, magicToken);
      const user = await authApi.me({ accessToken } );

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const smbMagicToken = useCallback(
    async (email: string, magicToken: string, chatId?: string): Promise<void> => {
      const { accessToken } = await authApi.handleCustomAuth(email, magicToken);
      const user = await authApi.me({ accessToken });
      if (user.sub && chatId) {
        await authApi.updateChatUserId(user.sub, chatId, accessToken);
      }

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const setNewPassword = useCallback(
    async (email: string, temporalPassword: string, newPassword: string): Promise<void> => {
      const { accessToken } = await authApi.setNewPassword(email, temporalPassword, newPassword);
      const user = await authApi.me({ accessToken } );

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  //temporarily deprecated, will bring back
  // const smbSignupEmail = useCallback(
  //   async (email: string, password: string, smb_id: string): Promise<void> => {
  //     const { accessToken } = await authApi.smbSignupEmail(email, password, smb_id);
  //     const user = await authApi.me({ accessToken });

  //     sessionStorage.setItem(STORAGE_KEY, accessToken);

  //     dispatch({
  //       type: ActionType.SIGN_UP,
  //       payload: {
  //         user,
  //       },
  //     });
  //   },
  //   [dispatch]
  // );

  const smbSignupConfirmToken = useCallback(
    async (token: string): Promise<void> => {
      const { accessToken } = await authApi.smbSignupConfirmToken(token);
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const smbSignupGoogle = useCallback(
    async (idp_code: string, smb_id: string): Promise<void> => {
      const response  = await authApi.smbSignupGoogle(idp_code, smb_id);
      const user = response;

      //sessionStorage.setItem(STORAGE_KEY, response);

      // dispatch({
      //   type: ActionType.SIGN_UP,
      //   payload: {
      //     user,
      //   },
      // });
    },
    [dispatch]
  );

  const smbSignupESToken = useCallback(
    async (token: string): Promise<void> => {
      const { accessToken } = await authApi.smbESTokenPush(token);
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const changePassword = useCallback(
    async (email: string, password: string, session: string): Promise<void> => {
      const { accessToken } = await authApi.changePassword({ email, password, session });
      const user = await authApi.me({ accessToken });
      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async (): Promise<void> => {
    sessionStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const signInWithCognitoCode = useCallback(
    async (code: string): Promise<any> => {
      const { id_token } = await authApi.signInWithCognitoCode(code);
      const user = await authApi.me({ accessToken: id_token });

      sessionStorage.setItem(STORAGE_KEY, id_token);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
      return user;
    },
    [dispatch]
  );

  const setHasChamberMembers = useCallback((hasChamberMembers: boolean) => {
    dispatch({ type: ActionType.SET_HAS_CHAMBER_MEMBERS, payload: { hasChamberMembers } });
  }, []);

  const setPlatformStage = useCallback((stage: PlatformStage) => {
    dispatch({ type: ActionType.SET_PLATFORM_STAGE, payload: { stage } });
  }, []);

  const enableTabs = useCallback((enable: boolean) => {
    dispatch({ type: ActionType.ENABLE_TABS, payload: { enable } });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        googleSignin,
        signInWithCognitoCode,
        confirmSignUp,
        // smbSignupEmail,
        smbSignupConfirmToken,
        smbSignupGoogle,
        smbSignupESToken,
        changePassword,
        setNewPassword,
        signOut,
        setHasChamberMembers,
        setPlatformStage,
        enableTabs,
        magicTokenVerify,
        smbMagicToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
